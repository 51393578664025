import React, { Component } from "react";
import { Link } from "react-router-dom";
import 'vanilla-hamburger/cross-burger.js';
import { withRouter } from "react-router";
import $ from 'jquery';
import axios from "axios";
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
class WebHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile_menu_toggled: false,
      filter_menu_visible: false,
      tags: [],
      search_results:[],
      keyword: '',
      search: false,
      work_filter: '',
      client_filter: '',
      color: ''
    };
    this.targetElement = document.querySelector('.menu')
  }
  toggleSearchFilter() {
      this.setState({filter_menu_visible: !this.state.filter_menu_visible})
  }

  get_tags() {
    axios
      .post(`${process.env.REACT_APP_API_URL}/tags`, {
        tag_type: "",
      })
      .then((res) => {
        this.setState({
          tags: res.data,
        });
      });
  }
  search_projects() {
    axios.post(`${process.env.REACT_APP_API_URL}/search_projects`, { keyword: this.state.keyword, work_filter: this.state.work_filter, client_filter: this.state.client_filter } ).then((res) => {
      console.log(res.data);
      this.setState({search_results: res.data[0], search: true, keyword: this.state.keyword});
      $('.main--container, .main--content').addClass('d-none');
      if ($('.main--container, .main--content').hasClass('fade_in')) {
        $('.main--container, .main--content').removeClass('fade_in')
      } else {
        return
      }
    })
  }

  searchExit() {
    setTimeout(function() {
      this.setState({search: false});
      if (this.state.search_results.length != 0) {
        $('.main--container, .main--content').addClass('fade_in')
      }
   }.bind(this), 500);
   this.setState({work_filter: '', client_filter: ''})
   $('.main--container, .main--content').removeClass('d-none');
   $('.searchResult').addClass('fade_out')
   if (document.querySelector('.search-filter-content').style.display = 'block') {
     $('.search-filter-content').hide();
   }
 }
  
    componentDidMount() {
      $('.filter-btn-container').on('click', '.filter--btn', function() {
        $(this).toggleClass('active').siblings().removeClass('active');
        if ($(this).hasClass('active')) {
          $('.search-filter-content').show();
          if ($(this).attr('id') == 'client-filter') {
            $('.type-of-client-filter').removeClass('v-none');
            $('.type-of-work-filter').addClass('v-none');
          }
          else if ($(this).attr('id') == 'work-filter') {
           $('.type-of-client-filter').addClass('v-none');
           $('.type-of-work-filter').removeClass('v-none')
          }
        }
        else {
          $('.search-filter-content').hide();
        }
      });
      $('.filter--btn').each(function(index) {
        $(this).on('click', function() {
          $(this).siblings().find('.state').text('+');
        $(this).find('.state').text() == '+' ? $(this).find('.state').text('-') : $(this).find('.state').text('+')
      })
      })
        $(document).ready(function(){
            $("#toggle-search").click(function(){
                var margin_top = $("#search-panel").outerHeight();
                $("#search-panel").slideDown("slow");
                $("#toggle-search").css("display", "none");
                $("#toggle-close").css("display", "block");
                $(".content--wrapper, .slider").css("marginTop", margin_top);
                // $("body").css("backgroundColor", "rgba(0,0,0,0.4)");
            });
            $("#toggle-close").click(function(){
              if ($('.searchResult').hasClass('active')) {
                $("#search-panel").delay(500).slideUp("medium");
                $(".content--wrapper, .slider").delay(600)
                .queue(function (next) { 
                  $(this).css('marginTop', '0px'); 
                  next();
              })
            }
              else {
                $("#search-panel").slideUp("medium");
                $(".content--wrapper, .slider").css("marginTop", "0px");
              }
                $("#toggle-close").css("display", "none");
                $("#toggle-search").css("display", "block");
            })
        });
        this.get_tags();
        }
         mobileMenu() {
          this.setState({mobile_menu_toggled: !this.state.mobile_menu_toggled})
    }
    mobileSearch() {
      this.setState({mobile_menu_toggled: false})
      enablePageScroll(this.targetElement)
      document.querySelector('cross-burger').click()
      var margin_top = $("#search-panel").outerHeight();
                $("#search-panel").slideDown("slow");
                $("#toggle-search").css("display", "none");
                $("#toggle-close").css("display", "block");
                $(".content--wrapper, .slider").css("marginTop", margin_top);
                // $("body").css("backgroundColor", "rgba(0,0,0,0.4)");
    }
    onNavigate() {
      this.setState({mobile_menu_toggled: false})
      document.querySelector('cross-burger').click()
    }
    componentWillUnmount() {

    }
    render() {
      if (this.state.mobile_menu_toggled == true) {
        disablePageScroll()
      }
      else {
        enablePageScroll()
      }
        return (
            <header>
  <div className="header_logo">
    <Link className="icon-logo" to="/">
    <svg version="1.1" id="protul" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2809.4 1117" style={{enableBackground: 'new 0 0 2809.4 1117'}} xmlSpace="preserve">
  <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t.st0{fill:#E51E2A;}\n" }} />
  <g id="group">
    <path className="st0" d="M0,246h82v117.2c32.5-44.5,69.8-77.9,112-100c42.2-22.1,89.3-33.2,141.3-33.2c90.7,0,168.3,32.6,232.8,97.8
		S665,471.5,665,563.2c0,93.7-32,173.2-95.9,238.7S428,900,337.7,900c-50.8,0-97.6-10.5-140.2-31.6S116.4,815.8,82,774v343H0V246z
		 M329.2,309c-71,0-130.8,24.8-179.4,74.2S77,494.3,77,568c0,48.3,10.8,91.8,32.5,130.4s52.7,69.2,93,91.8
		c40.4,22.6,83,33.9,127.9,33.9c44.1,0,85.5-11.4,124.3-34.2s69.7-54.7,92.8-95.6S582,610.4,582,565.6c0-45.2-11.4-88.1-34.2-128.6
		c-22.8-40.5-53.5-72-92.2-94.4S374.8,309,329.2,309z" />
    <path className="st0" d="M714.6,246h84v93.2c24.9-36.7,51.2-64.1,78.9-82.1s56.5-27,86.5-27c22.6,0,46.8,7.2,72.5,21.7l-42.8,69.5
		c-17.2-7.4-31.6-11.1-43.2-11.1c-27.3,0-53.6,11.2-78.9,33.7S827,401,813.8,448.2c-10.1,36.3-15.2,109.7-15.2,220.2V884h-84V246z" />
    <path className="st0" d="M1335.8,230c98.1,0,179.3,35.5,243.8,106.6c58.6,64.8,87.9,141.5,87.9,230.2c0,89-31,166.8-92.9,233.4
		c-61.9,66.6-141.5,99.9-238.8,99.9c-97.7,0-177.5-33.3-239.4-99.9c-61.9-66.6-92.9-144.4-92.9-233.4c0-88.2,29.3-164.8,87.9-229.6
		C1155.9,265.7,1237.3,230,1335.8,230z M1335.5,310c-67.9,0-126.2,25.2-174.9,75.6c-48.8,50.4-73.1,111.3-73.1,182.8
		c0,46.1,11.1,89,33.3,128.9c22.2,39.8,52.2,70.6,90.1,92.3c37.8,21.7,79.4,32.5,124.6,32.5c45.2,0,86.8-10.8,124.6-32.5
		c37.8-21.7,67.9-52.4,90.1-92.3c22.2-39.8,33.3-82.8,33.3-128.9c0-71.5-24.5-132.4-73.4-182.8S1402.9,310,1335.5,310z" />
    <path className="st0" d="M1819.2,10h82v236h130v71h-130v567h-82V317h-112v-71h112V10z" />
    <path className="st0" d="M2071.9,246h82v297.5c0,72.6,3.9,122.6,11.7,149.9c11.7,39.1,34.1,69.9,67.2,92.5c33.1,22.7,72.5,34,118.2,34
		c45.8,0,84.6-11,116.5-33.1c31.9-22.1,53.9-51,66-87c8.2-24.6,12.3-76.7,12.3-156.4V246h84v312.9c0,87.9-10.3,154.1-30.8,198.7
		s-51.4,79.4-92.6,104.6s-92.9,37.8-155,37.8c-62.1,0-114-12.6-155.6-37.8c-41.6-25.2-72.7-60.4-93.2-105.5s-30.8-113-30.8-203.6
		V246z" />
    <path className="st0" d="M2727.4,0h82v884h-82V0z" />
  </g>
</svg>
    </Link>
  </div>
  <nav className="nav__bar">
    <div className="item--container">
      <a className="header-item" href="https://www.protul.co/works">WORK</a>
      <a  className="header-item" href="https://www.protul.co/about">ABOUT</a>
      <a className="header-item" href="https://www.protul.co/news">NEWS</a>
      <a className="header-item" href="https://www.protul.co/contact">CONTACT</a>
    </div>
    <form method="post">
    <div id="search-panel">
        {/* <Search /> */}
        <div className="search-wrapper">
            <input onChange={(e) =>this.setState({keyword: e.target.value})} onInput={this.search_projects.bind(this)} className="search-field" type="text" placeholder="Find work by client, type, location, year" />
            <input onChange={(e) => this.setState({keyword: e.target.value})} onInput={this.search_projects.bind(this)} className="search-field-small-vw" type="text" placeholder="Find work" />
            <button className="search-submit" type="submit">Search</button>
        </div>
      <div className="filter-btn-container">
        <button type="button" onClick={()=> this.toggleSearchFilter()} id="client-filter" className="filter--btn"><span>Filter by type of client <span className="state">+</span> </span></button>
        <button type="button" onClick={()=> this.toggleSearchFilter()} id="work-filter" className="filter--btn"><span>Filter by type of work <span className="state">+</span> </span></button>
      </div>
      <div className="search-filter-content">
      <div className="type-of-client-filter">
      <ul>
      {
        this.state.tags.map((item,index)=> {
          if (item.type == 'Client') {
            return (
            <li key={index} ><input onChange={() => this.setState({client_filter: item.name}, function() {this.search_projects()})} id={index} name="filter"  type="radio" /> <label  for={index}>{item.name}</label></li>
          )
          }
        })
      }
        </ul>
      </div>
      <div className="type-of-work-filter">
      <ul style={{margin: '0'}}>
      {
        this.state.tags.map((item,index)=> {
          if (item.type == 'Work') {
            return (
            <li key={index}><input onChange={() => this.setState({work_filter: item.name}, function() {this.search_projects()})} id={index} name="filter"  type="radio" /> <label  for={index}>{item.name}</label></li>
          )
          }
        })
      }
        </ul>
      </div>
      </div>
      {
        this.state.search == true ?
          <section style={{paddingTop: '120px'}} className="workArchive searchResult active">
            <div className="workFeature-row">
              <div className="itemHeader">
                <h2>
                    <span style={{color: '#999', fontWeight: 'bold'}} className="searchResultTitle"><span style={{color: '#000'}}>{this.state.search_results.length} results</span>{" "} for {this.state.keyword}</span>
                </h2>
              </div>
              <div className="projectArchiveGrid">
              {
                  this.state.search_results.map((item,index) => {
                      return(
                        <div style={{paddingTop: '62.78%'}} className="projectArchiveItem1">
                    <a href={`https://www.protul.co/work/${item.title.replace(/\s+/g, "-")}`}>
                      <img style={{top: '0', position: 'absolute', left: '0'}}
                        src={`${process.env.REACT_APP_ASSETS_URL}/${item.images != '' ? item.images : '029_Everlasting_Sky.jpg'}`}
                      />
                      <div
                        style={{ position: "absolute", width: "100%" }}
                        className="itemTitle workFeature-title"
                      >
                        <span className="projectArchiveItemTitle">
                          {item.title}
                        </span>
                      </div>
                    </a>
                  </div>
                      )
                  })
              }
      
              </div>
            </div>
          </section>
          :
          <div className="searchResult"></div>
      }
    </div>

    </form>
    {/* onToggle={toggled => {
  if (toggled) {
     this.setState({mobile_menu_toggled: true})
     disablePageScroll(this.targetElement)
  } else {
     this.setState({mobile_menu_toggled: false})
     enablePageScroll(this.targetElement)
  }
}} */}
    <div className="menu-wrap">
      <cross-burger onClick={() => this.mobileMenu()} distance="sm" size={20} direction="left" />
    </div>
    {
      this.state.mobile_menu_toggled &&
      (
        <div className="menu">
  <div className="mobile-menu-list-container" style={{padding: '103px 20px 0px'}}>
    <div className="mobile-menu-list">
      <ul style={{padding: 0}}>
        <li><a onClick={()=> this.onNavigate()} href="https://www.protul.co/works">WORK</a></li>
        <li><a onClick={()=> this.onNavigate()} href="https://www.protul.co/about">ABOUT</a></li>
        <li><a onClick={()=> this.onNavigate()} href="https://www.protul.co/news">NEWS</a></li>
        <li><a onClick={()=> this.onNavigate()} href="https://www.protul.co/contact">CONTACT</a></li>
        <li onClick={() => this.mobileSearch()}><svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="25px" height="25px"><path d="M 20.5 6 C 12.509634 6 6 12.50964 6 20.5 C 6 28.49036 12.509634 35 20.5 35 C 23.956359 35 27.133709 33.779044 29.628906 31.75 L 39.439453 41.560547 A 1.50015 1.50015 0 1 0 41.560547 39.439453 L 31.75 29.628906 C 33.779044 27.133709 35 23.956357 35 20.5 C 35 12.50964 28.490366 6 20.5 6 z M 20.5 9 C 26.869047 9 32 14.130957 32 20.5 C 32 23.602612 30.776198 26.405717 28.791016 28.470703 A 1.50015 1.50015 0 0 0 28.470703 28.791016 C 26.405717 30.776199 23.602614 32 20.5 32 C 14.130953 32 9 26.869043 9 20.5 C 9 14.130957 14.130953 9 20.5 9 z" /></svg>
          <span>SEARCH</span></li>
      </ul>
    </div>
    <div className="mobile-menu-footer">
      <ul style={{margin: '0'}}>
        <li><a href="#">© 2015-{new Date().getFullYear()}</a></li>
        <li><a href="#">Protul Corporation</a></li>
      </ul>
      <ul>
        <li><a target="_blank" href="">Facebook</a></li>
        <li><a target="_blank" href="https://www.instagram.com/protul.co/">Instagram</a></li>
      </ul>
    </div>
  </div>
</div>
      )
    }
  </nav>
  <div className="nav--icon">
    <button id="toggle-search" className="header--btn"> <span className="search--icon"> <svg fill={this.state.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="25px" height="25px"><path d="M 20.5 6 C 12.509634 6 6 12.50964 6 20.5 C 6 28.49036 12.509634 35 20.5 35 C 23.956359 35 27.133709 33.779044 29.628906 31.75 L 39.439453 41.560547 A 1.50015 1.50015 0 1 0 41.560547 39.439453 L 31.75 29.628906 C 33.779044 27.133709 35 23.956357 35 20.5 C 35 12.50964 28.490366 6 20.5 6 z M 20.5 9 C 26.869047 9 32 14.130957 32 20.5 C 32 23.602612 30.776198 26.405717 28.791016 28.470703 A 1.50015 1.50015 0 0 0 28.470703 28.791016 C 26.405717 30.776199 23.602614 32 20.5 32 C 14.130953 32 9 26.869043 9 20.5 C 9 14.130957 14.130953 9 20.5 9 z" /></svg></span>
    </button>
    <button onClick={()=> this.searchExit()} id="toggle-close" className="header--btn"> <span className="close--icon"> <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" style={{enableBackground: 'new 0 0 512.001 512.001'}} xmlSpace="preserve" width="20px" height="20px">
          <g>
            <g>
              <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
          L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
          c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
          l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
          L284.286,256.002z" />
            </g>
          </g>
        </svg></span>
    </button>
  </div>
</header>
        )
    }
}

export default withRouter(WebHeader);