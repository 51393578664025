import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import {Helmet} from "react-helmet";
import {PrimaryButton} from '../Elements/primary-button';
import { SecondaryButton } from "../Elements/secondary-button";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {PopOver} from '../Elements/popover';
import Modal from "../Elements/modal";
import { NotificationBar } from "../Elements/notification-bar";

class Tags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tag_input_visible: false,
            tag_type: '',
            type_of_work: [],
            type_of_client: [],
            tag_name: '',
            modal_visible: null,
            save_change_modal_visible: false,
            edited_tag_name: '',
            edited_tag_uuid: '',
            edited_tag_url: '',
            edited_tag_type: '',
            success: false,
            api_response_message: '',
            notification_visible: false
        };
      }
      get_tags() {
        axios
          .post(`${process.env.REACT_APP_API_URL}/tags`, {
            tag_type: '',
          })
          .then((res) => {
            this.setState({
              type_of_work: res.data.filter(function(tag) {
                return tag.type == 'Work'
              }).sort((a,b) => a.sort_index - b.sort_index),
              type_of_client: res.data.filter(function(tag) {
                return tag.type == 'Client'
              }).sort((a,b) => a.sort_index - b.sort_index)
            });
          });
      }
        componentDidMount() {
            this.get_tags()
            }
        remove(uuid, type) {
                axios.post(`${process.env.REACT_APP_POST_URL}/remove`, {uuid: uuid, table: 'tags', tag_type: type}).then((res) =>{
                  console.log(res)
                  window.location = ''
                })
              }
    componentWillUnmount() {

    }
    uploadTag(tag_type) {
        axios.post(`${process.env.REACT_APP_POST_URL}/tags/add_tag`, {tag_name: this.state.tag_name, tag_url: '', tag_type: tag_type}).then((res) => {
            if (res.status == 200) {
                console.log(res)
                this.setState({notification_visible: true, success: true, api_response_message: 'Saved successfully'})
        setTimeout(() => {
          this.setState({notification_visible: false}, () => {
            window.location = ''
          })
        }, 3000)
            } else {
              this.setState({notification_visible: true, success: false, api_response_message: res.statusText})
        setTimeout(() => {
          this.setState({notification_visible: false})
        }, 3000)
            }
        }).catch((err) => {
          console.log(err)
          this.setState({notification_visible: true, success: false, api_response_message: 'Internal server error'})
        setTimeout(() => {
          this.setState({notification_visible: false}, () => {
          })
        }, 3000)
        })
    }
    onClickHandler(arg) {
        this.setState({tag_input_visible: true})
        this.setState({tag_type: arg})
    }
    onChangeHandler(e, uuid, type) {
      if (e.target.value.trim() == '') {
        e.target.classList.add('d-none')
        e.target.parentElement.querySelector('.item__title').classList.remove('d-none')
      }
      else {
        this.setState({edited_tag_name: e.target.value, edited_tag_uuid: uuid, edited_tag_type: type, edited_tag_url: ''}, () => {
          this.setState({save_change_modal_visible: true})
        })
      }
    }

    saveChangeHandler(e) {
      axios.post(`${process.env.REACT_APP_POST_URL}/tags/add_tag`, {tag_name: this.state.edited_tag_name, uuid: this.state.edited_tag_uuid, tag_url: '', tag_type: this.state.edited_tag_type}).then((res) => {
        if (res.status == 200) {
            console.log(res)
            this.setState({edited_tag_name: '', edited_tag_type: '', edited_tag_url: '', edited_tag_uuid: ''}, () => {
              window.location = ''
            })
        }
        if (!res.length > 0) {
          window.location = ''
        }  
    })
    }

    editHandler(e) {
      console.log(e.target.parentElement.closest('div').previousSibling.querySelector('.item__title').innerHTML)
      var parentElement = e.target.parentElement
      parentElement.closest('div').previousSibling.querySelector('.item__title').classList.add('d-none')
      parentElement.closest('div').previousSibling.querySelector('.project-form-field').classList.remove('d-none')
      parentElement.closest('div').previousSibling.querySelector('.project-form-field').focus()
    }

    handleOnDragEnd(result, source) {
      if (!result.destination) {
        return
      }
      const type_of_work_list = this.state.type_of_work
      const type_of_client_list = this.state.type_of_client
      if (source == 'type_of_work') {
        const [re_ordered_type_of_work_list] = type_of_work_list.splice(result.source.index, 1)
      type_of_work_list.splice(result.destination.index, 0, re_ordered_type_of_work_list)
      var ordered_list_props = []
      this.setState({type_of_work: type_of_work_list}, function () {
        this.state.type_of_work.map((tag, index) => {
          ordered_list_props.push({uuid: tag.uuid, index: index})
        })
        this.updateSort(ordered_list_props, 'tags')
      })
      }
      else if (source == 'type_of_client') {
        const [re_ordered_type_of_client_list] = type_of_client_list.splice(result.source.index, 1)
      type_of_client_list.splice(result.destination.index, 0, re_ordered_type_of_client_list)
      var ordered_list_props = []
      this.setState({type_of_client: type_of_client_list}, function () {
        this.state.type_of_client.map((tag, index) => {
          ordered_list_props.push({uuid: tag.uuid, index: index})
        })
        this.updateSort(ordered_list_props, 'tags')
      })
      }
    }

    async updateSort(list_props, source) {
      await axios.post(`${process.env.REACT_APP_POST_URL}/sort_order`, {table: source, list_props: list_props}).then((res) => {
        if (res.status == 200) {
          console.log('Sorted')
        }
        else {
          console.log('Error while sorting list')
        }
      }).catch(e => {
        console.log(e)
      })
    }

    modalHandler(index) {
      this.setState({ modal_visible: this.state.modal_visible !== index ? index : null })
    }

    saveChangeModalHandler() {
      this.setState({save_change_modal_visible: !this.state.save_change_modal_visible})
    }

    render() {
      var title = 'Tags — Protul Co'
      return(
          <div className="content--wrapper">
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <div className="main--container">
          <NotificationBar style={{position: 'absolute', width: '100%', top: '0', left: '0', right: '0', zIndex: '999'}} visible={this.state.notification_visible} success={this.state.success}>{this.state.api_response_message}</NotificationBar>
          {/* <div style={{borderBottom: '1px solid #e3e4e5' }} className="newsHeader">
            <div style={{display: 'flex', alignItems: 'center', position: 'relative', justifyContent: 'space-between'}} className="newsHeader__title">
              <h1 style={{width: '100%'}}>Tags</h1>
              {
                  this.state.tag_input_visible ?
                    <div style={{width: '100%'}} className="post_news_container">
              <form method="post" onSubmit={(e) => this.uploadTag(e)}>
              <div style={{paddingBottom: '2px'}} className="news-post-wrapper">
            <input onInput={(e)=> this.setState({tag_name: e.target.value})} className="news_link_input" type="text" placeholder="Tag Name" />
            <PrimaryButton type="submit">{this.state.tag_type == 'Work' ? 'Add Work' : 'Add Client'}</PrimaryButton>
        </div>
        </form>
              </div>
                  :
                  ''    
              }
              <div style={{width: '100%'}}></div>
            </div>
          </div> */}
          <div style={{borderBottom: '1px solid #e3e4e5' }} className="newsHeader">
            <div style={{display: 'flex', alignItems: 'center', position: 'relative', justifyContent: 'space-between'}} className="newsHeader__title">
              <div className="page_heading_wrapper">
              <h1 style={{margin: '0'}}>Tags</h1>
              <div className="page_heading_button_s_container">
              {
                  this.state.tag_name.trim() == '' ?
                  (
                    <PrimaryButton onClick={() => this.setState({tag_input_visible: true})} type={'button'}>Add</PrimaryButton>
                  )
                  :
                  (
                    <PopOver function_parameter={true} option_one_function={this.uploadTag.bind(this)} option_two_function={this.uploadTag.bind(this)} options={[{name: 'Add as Type of Work', function_parameter: 'Work'}, {name: 'Add as Type of Client', function_parameter: 'Client'}]} type={'button'}>Save</PopOver>
                  )
                }

              </div>
              </div>
              <div className="function_wrapper">
                    <div style={{width: '100%'}} className="post_news_container">
              
              <div style={{paddingBottom: '2px', width: '100%'}} className="news-post-wrapper">
            {/* <input list="projects" onInput={(e)=> this.setState({project_name: e.target.value})} className="news_link_input" type="text" placeholder="Project Name" /> */}
            {
              this.state.tag_input_visible == true &&
              <input onInput={(e)=> this.setState({tag_name: e.target.value})} className="post_input_field" type="text" placeholder="Tag Name" />
            }
            {/* <button style={{border: 'none', width: '66%', padding: '0'}} type="submit" class="btn btn-outline-primary"> Add <span>{this.state.upload_type == 'Carousel' ? 'Carousel' : 'Highlight'}</span> </button> */}
        </div>
   
              </div>
        
              <div className="page_heading_button_l_container" style={{width: '100%', textAlign: 'right'}}>
                {
                  this.state.tag_name.trim() == '' ?
                  (
                    <PrimaryButton onClick={() => this.setState({tag_input_visible: true})} type={'button'}>Add</PrimaryButton>
                  )
                  :
                  (
                    <PopOver function_parameter={true} option_one_function={this.uploadTag.bind(this)} option_two_function={this.uploadTag.bind(this)} options={[{name: 'Add as Type of Work', function_parameter: 'Work'}, {name: 'Add as Type of Client', function_parameter: 'Client'}]} type={'button'}>Save</PopOver>
                  )
                }

              </div>
              </div>
            </div>
          </div>
          <div className="tags-container">
          <div className="tags-wrapper">
          <div className="tag-header">
          <h4>Type of Work</h4>
          {/* <button onClick={()=> this.onClickHandler('Work')}><span><svg id="add_icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" style={{enableBackground: 'new 0 0 50 50'}} xmlSpace="preserve">
  <circle style={{fill: '#43B05C'}} cx={25} cy={25} r={25} />
  <line style={{fill: 'none', stroke: '#FFFFFF', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10}} x1={25} y1={13} x2={25} y2={38} />
  <line style={{fill: 'none', stroke: '#FFFFFF', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10}} x1="37.5" y1={25} x2="12.5" y2={25} />
  <g>
  </g>
  <g>
  </g>
</svg></span></button> */}
          </div>
          <div className="tag-list">
            <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, 'type_of_work')}>
              <Droppable droppableId="type_of_work_list">
                {
                  (provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
          {
              this.state.type_of_work.map((item, index) => {
                  return (
                    <Draggable key={index} draggableId={`${index}`} index={index}>
                      {
                        (provided) => (
                          <div  className="draggable-list-container" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                            <Modal shown={this.state.modal_visible == index ? true : false} close={() => this.modalHandler(index)}>
                                          <Modal.Header>Confirmation</Modal.Header>
                                          <Modal.Body>Are you sure you want to delete {item.name}?</Modal.Body>
                                          <Modal.Footer>
                                            <PrimaryButton onClick={() => this.remove(item.uuid)} style={{ float: 'right' }}>Save</PrimaryButton>
                                            <SecondaryButton onClick={() => { this.modalHandler(index) }} >Cancel</SecondaryButton>
                                          </Modal.Footer>
                                        </Modal>
                            <li>
                              <span className="item__title">{item.name}</span>
                              <input list="projects" onBlur={(e) => this.onChangeHandler(e, item.uuid, item.type)} type="text" className="project-form-field d-none" />
                            </li>
                            <div style={{ position: 'relative', whiteSpace: 'nowrap' }}>
                              <button onClick={(e) => this.editHandler(e)} style={{ top: 'unset', right: 'unset', position: 'unset', marginRight: '5px' }} className="edit--btn"><svg className="edit--svg" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 528.899 528.899" style={{ enableBackground: 'new 0 0 528.899 528.899' }} xmlSpace="preserve">
                                <g>
                                  <path d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
        c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
        C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
        L27.473,390.597L0.3,512.69z" />
                                </g>
                                <g>
                                </g>
                              </svg>
                              </button>
                              <button onClick={() => this.modalHandler(index)} className="remove-button"><svg height="20px" viewBox="0 0 512 512" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336" /><path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa" /></svg></button>
                            </div>
                          </div>
                        )
                      }
                    </Draggable>
                  )
              })
          }
               {provided.placeholder}
          </ul>     
                  )
                }
              </Droppable>
            </DragDropContext>
          </div>
          
          </div>
          <div className="tags-wrapper">
          <div className="tag-header">
          <h4>Type of Client</h4>
          {/* <button onClick={()=> this.onClickHandler('Client')}><span><svg id="add_icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" style={{enableBackground: 'new 0 0 50 50'}} xmlSpace="preserve">
  <circle style={{fill: '#43B05C'}} cx={25} cy={25} r={25} />
  <line style={{fill: 'none', stroke: '#FFFFFF', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10}} x1={25} y1={13} x2={25} y2={38} />
  <line style={{fill: 'none', stroke: '#FFFFFF', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10}} x1="37.5" y1={25} x2="12.5" y2={25} />
  <g>
  </g>
  <g>
  </g>
</svg></span></button> */}
          </div>
          <div className="tag-list">
            <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, 'type_of_client')}>
              <Droppable droppableId="type_of_client_list">
                {
                  (provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                      {
                        this.state.type_of_client.map((value, index) => {
                            return (
                              <Draggable key={index} draggableId={`${index}`} index={index}>
                                {
                                  (provided) => (
                                    <div className="draggable-list-container" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                <Modal shown={this.state.modal_visible == index ? true : false} close={() => this.modalHandler(index)}>
                                          <Modal.Header>Confirmation</Modal.Header>
                                          <Modal.Body>Are you sure you want to delete {value.name}?</Modal.Body>
                                          <Modal.Footer>
                                            <PrimaryButton onClick={() => this.remove(value.uuid)} style={{ float: 'right' }}>Save</PrimaryButton>
                                            <SecondaryButton onClick={() => { this.modalHandler(index) }} >Cancel</SecondaryButton>
                                          </Modal.Footer>
                                        </Modal>
                                <li>
                                  <span className="item__title">{value.name}</span>
                                  <input list="projects" onBlur={(e) => this.onChangeHandler(e, value.uuid, value.type)} type="text" className="project-form-field d-none" />
                                </li>
                                <div style={{ position: 'relative', whiteSpace: 'nowrap' }}>
                                  <button onClick={(e) => this.editHandler(e)} style={{ top: 'unset', right: 'unset', position: 'unset', marginRight: '5px' }} className="edit--btn"><svg className="edit--svg" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 528.899 528.899" style={{ enableBackground: 'new 0 0 528.899 528.899' }} xmlSpace="preserve">
                                    <g>
                                      <path d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
		c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
		C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
		L27.473,390.597L0.3,512.69z" />
                                    </g>
                                    <g>
                                    </g>
                                  </svg>
                                  </button>
                                  <button onClick={() => this.modalHandler(index)} className="remove-button"><svg height="20px" viewBox="0 0 512 512" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336" /><path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa" /></svg></button>
                                </div>
                              </div>
                                  )
                                }
                              </Draggable>
                            )
                        })
                      }
                      {provided.placeholder}
                    </ul>
                  )
                }
              </Droppable>
            </DragDropContext>
          </div>
          
          </div>
          </div>
          </div>
          <Modal shown={this.state.save_change_modal_visible} close={() => this.saveChangeModalHandler.bind(this)}>
            <Modal.Header>Confirmation</Modal.Header>
            <Modal.Body>Are you sure you want to save your changes?</Modal.Body>
            <Modal.Footer>
              <PrimaryButton onClick={() => this.saveChangeHandler()} style={{ float: 'right' }}>Save</PrimaryButton>
              <SecondaryButton onClick={() => { this.saveChangeModalHandler() }} >Cancel</SecondaryButton>
            </Modal.Footer>
          </Modal>
          </div>
      )
    }
  }

  export default Tags;