import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import { Helmet } from "react-helmet";
import { PrimaryButton } from "../Elements/primary-button";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from "react-select";
import { PopOver } from '../Elements/popover';
import Modal from '../Elements/modal';
import { SecondaryButton } from "../Elements/secondary-button";
import { NotificationBar } from "../Elements/notification-bar";
const customStyles = {
  container: (provided, state) => (
    {
      ...provided,
      width: '100%',
      height: `${state.selectProps.price_selection == true ? 'unset' : '100%'}`,
    }
  ),
  control: (provided, state) => (
    {
      ...provided,
      height: `${state.selectProps.price_selection == true ? 'unset' : '100%'}`,
      borderRadius: '0',
      borderBottom: '0',
      border: '0',
      backgroundColor: state.selectProps.price_selection == true ? 'transparent' : 'transparent'
    }
  ),
  valueContainer: (provided, state) => (
    {
      ...provided,
      padding: '0 0 0 16px',

    }
  ),
  indicatorSeperator: (provided, state) => (
    {
      ...provided,
      display: 'none',
      width: '0'
    }
  ),
  menu: (provided, state) => (
    {
      ...provided,
      margin: '0',
      boxShadow: 'none',
      borderRadius: '0',
      border: '1px solid #006AFF',
      borderTop: '0',
      width: 'calc(100% + 2px)',
      left: '-1px'
    }
  ),
  menuList: (provided, state) => (
    {
      ...provided,
      padding: '0',
      borderRadius: '0'
    }
  ),
  singleValue: (provided, state) => (
    {
      ...provided,
      fontWeight: state.selectProps.price_selection == true ? '500' : 'initial'
    }
  )
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: undefined,
      projects: [],
      highlights: [],
      project_name: undefined,
      post_input_visible: false,
      upload_type: '',
      carousel: [],
      edit_carousel: false,
      edit_highlighted_project: false,
      options: [],
      modal_visible: null,
      notification_visible: false,
      api_response_message: '',
      success: false
    };
  }
  get_projects() {
    axios.post(`${process.env.REACT_APP_API_URL}/projects`, { url: '' }).then((res) => {
      this.setState({
        projects: res.data[0], options: res.data[0].map((item, index) => {
          return { value: item.title, label: item.title }
        })
      });
      console.log(this.state.options);
    });
  }
  get_highlights() {
    axios.post(`${process.env.REACT_APP_API_URL}/highlights`).then((res) => {
      this.setState({ highlights: res.data });
    });
  }
  get_carousel() {
    axios
      .post(`${process.env.REACT_APP_API_URL}/carousel`)
      .then((res) => {
        this.setState({
          carousel: res.data
        });
        console.log(this.state.carousel)
      });
  }
  componentDidMount() {
    this.get_projects()
    this.get_carousel()
    this.get_highlights()
  }
  remove(uuid) {
    axios.post(`${process.env.REACT_APP_POST_URL}/remove`, { uuid: uuid, table: 'carousel' }).then((res) => {
      console.log(res)
      window.location = ''
    })
  }
  removeHighlight(uuid) {
    axios.post(`${process.env.REACT_APP_POST_URL}/remove_highlight`, { uuid: uuid, table: 'projects' }).then((res) => {
      console.log(res)
      window.location = ''
    })
  }
  componentWillUnmount() {

  }
  uploadCarousel() {
    console.log(this.state.project_name)
    axios.post(`${process.env.REACT_APP_POST_URL}/carousel/add_carousel`, { project_name: this.state.project_name.value, uuid: this.state.uuid }).then((res) => {
      if (res.status == 200) {
        this.setState({notification_visible: true, success: true, api_response_message: 'Saved successfully'})
        setTimeout(() => {
          this.setState({notification_visible: false}, () => {
            window.location = ''
          })
        }, 3000)
      }
      else {
        this.setState({notification_visible: true, success: false, api_response_message: res.statusText})
        setTimeout(() => {
          this.setState({notification_visible: false})
        }, 3000)
      }
    }).catch(err => {
      console.log(err)
      this.setState({notification_visible: true, success: false, api_response_message: 'Internal server error'})
        setTimeout(() => {
          this.setState({notification_visible: false})
        }, 3000)
    })
  }
  uploadHighlight() {
    axios.post(`${process.env.REACT_APP_POST_URL}/projects/highlighted_project`, { project_name: this.state.project_name.value }).then((res) => {
      if (res.status == 200) {
        this.setState({notification_visible: true, success: true, api_response_message: 'Saved successfully'})
        setTimeout(() => {
          this.setState({notification_visible: false}, () => {
            window.location = ''
          })
        }, 3000)
      } else {
        this.setState({notification_visible: true, success: false, api_response_message: res.statusText})
        setTimeout(() => {
          this.setState({notification_visible: false}, () => {
          })
        }, 3000)
      }
      if (!res.length > 0) {
        window.location = ''
      }
    }).catch((err) => {
      console.log(err)
      this.setState({notification_visible: true, success: false, api_response_message: 'Internal server error'})
        setTimeout(() => {
          this.setState({notification_visible: false})
        }, 3000)
    })
  }
  onClickHandler() {
    this.setState({ post_input_visible: true })
  }
  editHandler(e) {
    console.log(e.target.parentElement)
    var parentElement = e.target.parentElement
    parentElement.closest('div').previousSibling.querySelector('.item__title').classList.add('d-none')
    parentElement.closest('div').previousSibling.querySelector('.project-form-field').classList.remove('d-none')
    parentElement.closest('div').previousSibling.querySelector('.project-form-field').focus()
  }
  onChangeHandler(e, uuid) {
    if (e.target.value.trim() == '') {
      e.target.classList.add('d-none')
      e.target.parentElement.querySelector('.item__title').classList.remove('d-none')
    }
    else {
      if (window.confirm('Are you sure you want to save your changes?')) {
        axios.post(`${process.env.REACT_APP_POST_URL}/carousel/add_carousel`, { project_name: e.target.value, uuid: uuid }).then((res) => {
          if (res.status == 200) {
            console.log(res)
            window.location = ''
          }
          if (!res.length > 0) {
            window.location = ''
          }

        })
      }
    }
  }

  handleOnDragEnd(result, source) {
    if (!result.destination) {
      return
    }
    console.log(result)
    const carousel_list = this.state.carousel
    const highlights_list = this.state.highlights
    if (source == 'carousel') {
      const [re_ordered_carousel_list] = carousel_list.splice(result.source.index, 1)
      carousel_list.splice(result.destination.index, 0, re_ordered_carousel_list)
      var ordered_list_props = []
      this.setState({ carousel: carousel_list }, function () {
        this.state.carousel.map((carousel, index) => {
          ordered_list_props.push({ uuid: carousel.carousel.uuid, index: index })
        })
        this.updateSort(ordered_list_props, source)
      })
    }
    else if (source == 'projects') {
      const [re_ordered_highlights_list] = highlights_list.splice(result.source.index, 1)
      highlights_list.splice(result.destination.index, 0, re_ordered_highlights_list)
      var ordered_list_props = []
      this.setState({ highlights: highlights_list }, function () {
        this.state.highlights.map((highlight, index) => {
          ordered_list_props.push({ uuid: highlight.uuid, index: index })
        })
        this.updateSort(ordered_list_props, source)
      })
    }
  }

  async updateSort(list_props, source) {
    await axios.post(`${process.env.REACT_APP_POST_URL}/sort_order`, { table: source, list_props: list_props }).then((res) => {
      if (res.status == 200) {
        console.log('Sorted')
      }
      else {
        console.log('Error while sorting list')
      }
    }).catch(e => {
      console.log(e)
    })
  }

  modalHandler(index) {
    this.setState({ modal_visible: this.state.modal_visible !== index ? index : null })
  }


  render() {
    var title = 'Home — Protul Co'
    return (
      <div className="content--wrapper">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className="main--container">
        <NotificationBar style={{position: 'absolute', width: '100%', top: '0', left: '0', right: '0', zIndex: '999'}} visible={this.state.notification_visible} success={this.state.success}>{this.state.api_response_message}</NotificationBar>
          <div style={{ borderBottom: '1px solid #e3e4e5' }} className="newsHeader">
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative', justifyContent: 'space-between' }} className="newsHeader__title">
              <div className="page_heading_wrapper">
                <h1 style={{ margin: '0' }}>Home</h1>
                <div className="page_heading_button_s_container">
                  {
                    this.state.project_name == undefined ?
                      (
                        <PrimaryButton onClick={() => this.onClickHandler()} type={'button'}>Add</PrimaryButton>
                      )
                      :
                      (
                        <PopOver function_parameter={false} option_one_function={this.uploadCarousel.bind(this)} option_two_function={this.uploadHighlight.bind(this)} options={[{ name: 'Add to Carousel' }, { name: 'Add to Highlight' }]} type={'button'}>Save</PopOver>
                      )
                  }

                </div>
              </div>
              <div className="function_wrapper">
                {
                  this.state.post_input_visible ?
                    <div style={{ width: '100%' }} className="post_news_container">

                      <div style={{ paddingBottom: '2px', width: '100%' }} className="news-post-wrapper">
                        {/* <input list="projects" onInput={(e)=> this.setState({project_name: e.target.value})} className="news_link_input" type="text" placeholder="Project Name" /> */}
                        <Select
                          styles={customStyles}
                          placeholder={'Select Project'}
                          id="carousel_select"
                          value={this.state.project_name}
                          onChange={(e) => this.setState({ project_name: e }, function () { console.log(this.state.project_name) })}
                          options={this.state.options}
                          components={{
                            IndicatorSeparator: () => null
                          }}
                        />
                        {/* <button style={{border: 'none', width: '66%', padding: '0'}} type="submit" class="btn btn-outline-primary"> Add <span>{this.state.upload_type == 'Carousel' ? 'Carousel' : 'Highlight'}</span> </button> */}
                      </div>

                    </div>
                    :
                    ''
                }
                <div className="page_heading_button_l_container" style={{ width: '100%', textAlign: 'right' }}>
                  {
                    this.state.project_name == undefined ?
                      (
                        <PrimaryButton onClick={() => this.onClickHandler()} type={'button'}>Add</PrimaryButton>
                      )
                      :
                      (
                        <PopOver function_parameter={false} option_one_function={this.uploadCarousel.bind(this)} option_two_function={this.uploadHighlight.bind(this)} options={[{ name: 'Add to Carousel' }, { name: 'Add to Highlight' }]} type={'button'}>Save</PopOver>
                      )
                  }

                </div>
              </div>
            </div>
          </div>
          <div className="tags-container">
            <div className="tags-wrapper">
              <div className="tag-header">
                <h4>Carousel</h4>
              </div>
              <div className="numbered-list">
                <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, 'carousel')}>
                  <Droppable droppableId="carouselList">
                    {
                      (provided) => (
                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                          {
                            this.state.carousel.map((item, index) => {
                              return (
                                <Draggable key={index} draggableId={`${index}`} index={index}>
                                  {
                                    (provided) => (
                                      <div className="draggable-list-container" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                        <Modal shown={this.state.modal_visible == index ? true : false} close={() => this.modalHandler(index)}>
                                          <Modal.Header>Confirmation</Modal.Header>
                                          <Modal.Body>Are you sure you want to delete {item.title}?</Modal.Body>
                                          <Modal.Footer>
                                            <PrimaryButton onClick={() => this.remove(item.uuid)} style={{ float: 'right' }}>Save</PrimaryButton>
                                            <SecondaryButton onClick={() => { this.modalHandler(index) }} >Cancel</SecondaryButton>
                                          </Modal.Footer>
                                        </Modal>
                                        <li style={{ display: 'flex' }}>
                                          <span className="item__title">{item.projects.title}</span>
                                          <input list="projects" onBlur={(e) => this.onChangeHandler(e, item.projects.uuid)} type="text" className="project-form-field d-none" />
                                        </li>
                                        <div style={{ position: 'relative', whiteSpace: 'nowrap' }}>
                                          {/* <button onClick={(e) => this.editHandler(e)} style={{ top: 'unset', right: 'unset', position: 'unset', marginRight: '5px' }} className="edit--btn"><svg className="edit--svg" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 528.899 528.899" style={{ enableBackground: 'new 0 0 528.899 528.899' }} xmlSpace="preserve">
                                              <g>
                                                <path d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
		c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
		C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
		L27.473,390.597L0.3,512.69z" />
                                              </g>
                                              <g>
                                              </g>
                                            </svg>
                                            </button> */}
                                          <button onClick={() => this.modalHandler(index)} className="remove-button"><svg height="20px" viewBox="0 0 512 512" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336" /><path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa" /></svg></button>
                                        </div>
                                      </div>
                                    )

                                  }

                                </Draggable>

                              )
                            })
                          }
                          {provided.placeholder}
                        </ul>
                      )
                    }
                  </Droppable>
                </DragDropContext>
              </div>

            </div>
            <div className="tags-wrapper">
              <div className="tag-header">
                <h4>Highlights</h4>
              </div>
              <div className="numbered-list">
                <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, 'projects')}>
                  <Droppable droppableId="highlightsList">
                    {
                      (provided) => (
                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                          {
                            this.state.highlights.map((value, index) => {
                              return (
                                <Draggable key={index} draggableId={`${index}`} index={index}>
                                  {
                                    (provided) => (
                                      <div className="draggable-list-container" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                        <Modal shown={this.state.modal_visible == index ? true : false} close={() => this.modalHandler(index)}>
                                          <Modal.Header>Confirmation</Modal.Header>
                                          <Modal.Body>Are you sure you want to delete {value.title}?</Modal.Body>
                                          <Modal.Footer>
                                            <PrimaryButton onClick={() => this.remove(value.uuid)} style={{ float: 'right' }}>Save</PrimaryButton>
                                            <SecondaryButton onClick={() => { this.modalHandler(index) }} >Cancel</SecondaryButton>
                                          </Modal.Footer>
                                        </Modal>
                                        <li>{value.title} </li>
                                        <button onClick={() => this.modalHandler(index)} className="remove-button"><svg height="20px" viewBox="0 0 512 512" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336" /><path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa" /></svg></button>
                                      </div>
                                    )
                                  }
                                </Draggable>
                              )
                            })
                          }
                          {provided.placeholder}
                        </ul>
                      )
                    }
                  </Droppable>
                </DragDropContext>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Home;