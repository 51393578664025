/* 
  filename: Dropzone.js 
*/

import React, {createRef} from "react";
// Import the useDropzone hooks from react-dropzone
import Dropzone, { useDropzone } from "react-dropzone";



const DropzoneComponent = ({ onDrop, accept, maxSize, onDropRejected, children, noClick, maxFiles, content_exists, index, ref }) => {
  // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    onDropRejected,
    maxSize,
    noClick,
    maxFiles,
    ref
  });

  return (
    <div {...getRootProps()}>
      <input id={`dropzone-${index}`} className="dropzone-input" {...getInputProps()} />
      <div className="text-center">
        {isDragActive ? (
          <p className="dropzone-content">Release to drop the files here</p>
        ) : (
          !content_exists &&
          <p className="dropzone-content">
            Drag 'n' drop your files here, or tap to add contents
          </p>
        )}
      </div>
      {children}
    </div>
  );
};


export default DropzoneComponent;